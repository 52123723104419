import { React, useState, useEffect } from "react";
import {Link} from "react-router";
import hamburger from "./assets/hamburger_menu.png";
import shop from "./assets/bag.png";
import axios from "axios";
import { load } from "@cashfreepayments/cashfree-js";
import logo from './assets/ruh_logo.png'
import right_icon from './assets/right_icon.png'

function Primary() {
  
  const initalValues ={
    parent_name: "",
    learner_name: "",
    learner_email: "",
    grade: "",
    activity_mon: null,
    activity_tue: null,
    activity_sat: null,
    amount: undefined,
  };
  const [formValues, setFormValues] = useState(initalValues);
  const [formErrors, setFormErrors] = useState({});
  // const [isSubmit, setIsSubmit] = useState(false);
   const[orderId,setOrderId]=useState('')
   const[fee,setFee]=useState('')

  const calculatePayment = () => {
    const selectedEvents = [formValues.activity_mon, formValues.activity_tue, formValues.activity_sat].filter(value => value != null);
    const numberofEvents = selectedEvents.length;
    // const payment_plan = (formValues.payment_plan === "Annually" ? 12 : 1);

    const costofEvent = 2400;
    const amount = (costofEvent * numberofEvents);
    console.log("amount",amount);
     setFee(amount)
    setFormValues((prevValues) => ({
      ...prevValues,
      amount: amount
    }));
  }
    const handleChange = (e) => {
      const{name, value , type} = e.target;
      // setFormValues({...formValues, [name]: value})
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: type === "button" ? (prevValues[name] === value ? null : value) : value,
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined, // Remove the error for this field
      }));
  
      console.log(formValues.activity_mon, formValues.activity_tue, formValues.activity_sat);
  
    }
    useEffect(() => {
      calculatePayment();
    },[formValues.activity_mon,formValues.activity_tue, formValues.activity_sat])
  
    const validate = (values) => {
      const errors = {};
      const regex =  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
      if(!values.parent_name){
        errors.parent_name = 1;
      };
  
      if(!values.learner_name){
        errors.learner_name = 1
      };
  
      if(!values.learner_email){
        errors.learner_email = 1
      };
  
      if(!values.grade){
        errors.grade= 1;
      };
  
      if(!values.activity_mon && !values.activity_tue && !values.activity_sat){
        errors.activity_mon = 1;
        errors.activity_tue = 1;
        errors.activity_sat = 1;
      }

      if(!values.payment_order_id){
        errors.payment_order_id=1;
      }
  
      return errors;
    };
  
  let cashfree;
  let initializeSDK = async function () {
    cashfree = await load({
      mode: "sandbox",
    });
  };
  initializeSDK();

  const getSessionId = async () => {
    try {
      let res = await axios.post("https://ruhftop.el.r.appspot.com/payment",formValues,{
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      });
      setOrderId(res.data.order_id)
      if (res.data && res.data.payment_session_id) {
        return {  payment_session_id: res.data.payment_session_id, order_id: res.data.order_id };
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePayment = async (e) => {
    
    e.preventDefault();
    setFormErrors(validate(formValues));
    console.log(formValues);
    console.log(formErrors); 
    

    try {
      let sessionData = await getSessionId();
      const { payment_session_id, order_id } = sessionData;
      let checkoutOptions = {
        paymentSessionId: payment_session_id,
        redirectTarget: "_modal",
      };
      cashfree.checkout(checkoutOptions).then((res) => {
        console.log("Muhil");
        const obj = { ...formValues, payment_order_id: order_id };
        handleGoogleSheet(obj)
        verifyPayment(order_id)
        window.location.href= "https://www.ruhftop.in/";
        setFormValues(initalValues)
        setFormErrors({});
      });
    } catch (error) {
      console.log(error);
    }
  };


  const handleGoogleSheet = (formValues) => {
    // console.log("formValues from google sheet",formValues)
   fetch(
     "https://script.google.com/macros/s/AKfycbwA4w6-eQaS-zmK-2YgFmvH2t-w9k_v4zG0h4CHUf8oVMppnk_a2acoBleVCxkk0D3cSg/exec",
     {
       method: "POST",
       headers: { "Content-Type": "application/x-www-form-urlencoded" },
       body: new URLSearchParams(formValues).toString(), // Correctly encode form data
     }
   )
     .then((res) => res.text(console.log("response", res)))
     .then((data) => {
       console.log("data", data);
     })
     .catch((error) => {
       console.log("error", error);
     });
 };


  const verifyPayment = async (order_id) => {
    try {
      let res = await axios.post("https://ruhftop.el.r.appspot.com//verify", {
        orderId: order_id,
      });
      if (res && res.data) {
        alert("payment verified");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formValues.payment_order_id) {
      handleGoogleSheet(formValues);
    }
  }, [formValues.payment_order_id]);
  

  return (
    <>
      {/* <Navbar> */}
      <div className='bg-[#515d45] p-5 flex flex-row justify-between items-center  '>
        {/* <img src={hamburger} alt='mobile-menu' className='w-[30px] h-[30px]' /> */}
        <a href="https://www.ruhftop.in"><img src={logo} alt='brand_logo' className='w-[160px]' /></a>
        {/* <img src={shop} alt='mobile-menu' className='w-[30px] h-[30px]' /> */}
        <Link to = "/early_years"><button type="button" className="text-white font-[Causten] text-[20px] capitalize">Go To Early Years</button></Link>
      </div>

      {/* Form */}

      <div className='bg-[#ffffec]'>
        <div className='max-w-md mx-auto p-5'>
          <form onSubmit={handlePayment}>
            <div className='flex flex-col gap-y-5'>

            <h1 className='text-2xl font-[Causten] capitalize text-center'>Primary - Activity Details</h1>
        
              {/* Mon & Wed Buttons */}
              <div className=''>
                <h3 className='font-[Causten] text-[18px] text-black font-semibold mb-3'>Monday & Wednesday 3.30PM</h3>
                 <div className='grid grid-cols-3 gap-y-3'>
                 <button 
                      type='button' 
                      name='activity_mon' 
                      value="Basketball" 
                      className={`bg-white rounded-md border-[1.8px] border-[#808080] text-black font-semibold font-[Causten] text-[14px] h-[60px] w-[110px] ${formValues.activity_mon === "Basketball" ? "!bg-[#515d45] text-white font-bold" : formErrors.activity_mon === 1 ? "border-2 !border-[#FF0000]" :"" } `} 
                      onClick={handleChange}
                    >Basketball
                  </button>

                    <button 
                      type='button' 
                      name='activity_mon' 
                      value="Silambam & Kalari" 
                      className={`bg-white rounded-md border-[1.8px] border-[#808080] text-black font-semibold font-[Causten] text-[14px] h-[60px] w-[110px] ${formValues.activity_mon === "Silambam & Kalari" ? "!bg-[#515d45] text-white font-bold" : formErrors.activity_mon === 1 ? "border-2 !border-[#FF0000]" :"" } `} 
                      onClick={handleChange}
                    >Silambam /<br /> Kalari
                    </button>

                    <button 
                      type='button' 
                      name='activity_mon' 
                      value="Speech & Drama" 
                      className={`bg-white rounded-md border-[1.8px] border-[#808080] text-black font-semibold font-[Causten] text-[14px] h-[60px] w-[110px] ${formValues.activity_mon === "Speech & Drama" ? "!bg-[#515d45] text-white font-bold" : formErrors.activity_mon === 1 ? "border-2 !border-[#FF0000]" :"" } `} 
                      onClick={handleChange}
                    >Speech & <br /> Drama
                    </button>

                    <button 
                      type='button' 
                      name='activity_mon' 
                      value="Piano" 
                      className={`bg-white rounded-md border-[1.8px] border-[#808080] text-black font-semibold font-[Causten] text-[14px] h-[60px] w-[110px] ${formValues.activity_mon === "Piano" ? "!bg-[#515d45] text-white font-bold" : formErrors.activity_mon === 1 ? "border-2 !border-[#FF0000]" :"" } `} 
                      onClick={handleChange}>Piano
                    </button>

                    <button 
                      type='button' 
                      name='activity_mon' 
                      value="Handwriting" 
                      className={`bg-white rounded-md border-[1.8px] border-[#808080] text-black font-semibold font-[Causten] text-[14px] h-[60px] w-[110px] ${formValues.activity_mon === "Handwriting" ? "!bg-[#515d45] text-white font-bold" : formErrors.activity_mon === 1 ? "border-2 !border-[#FF0000]" :"" } `} 
                      onClick={handleChange}>Handwriting
                    </button>
                 </div>
                
              </div>

              {/* Tue & Thrus Buttons */}
              <div className=''>
                <h3 className='font-[Causten] text-[18px] text-black font-semibold mb-3'>Tuesday & Thursday 3.30PM</h3>
                  <div className='grid grid-cols-3 items-center gap-y-3'>
                    <button 
                        type='button' 
                        name='activity_tue' 
                        value="Soccer" 
                        className={`bg-white rounded-md border-[1.8px] border-[#808080] text-black font-semibold font-[Causten] text-[14px] h-[60px] w-[115px] ${formValues.activity_tue === "Soccer" ? "!bg-[#515d45] text-white font-bold" :formErrors.activity_tue === 1 ? "border-2 !border-[#FF0000]" :"" } `} 
                        onClick={handleChange}
                        disabled = {formValues.activity_tue === "Skating" && formValues.activity_tue != null}
                      > Soccer (Foundation)
                      </button>

                      <button 
                        type='button' 
                        name='activity_tue' 
                        value="Pickleball" 
                        className={`bg-white rounded-md border-[1.8px] border-[#808080] text-black font-semibold font-[Causten] text-[14px] h-[60px] w-[115px] ${formValues.activity_tue === "Pickleball" ? "!bg-[#515d45] text-white font-bold" :formErrors.activity_tue === 1 ? "border-2 !border-[#FF0000]" :"" } `} 
                        onClick={handleChange}
                        disabled = {formValues.activity_tue === "Skating" && formValues.activity_tue != null}
                      > Pickelball
                      </button>

                      <button 
                        type='button' 
                        name='activity_tue' 
                        value="Yoga" 
                        className={`bg-white rounded-md border-[1.8px] border-[#808080] text-black font-semibold font-[Causten] text-[14px] h-[60px] w-[115px] ${formValues.activity_tue === "Yoga" ? "!bg-[#515d45] text-white font-bold" :formErrors.activity_tue === 1 ? "border-2 !border-[#FF0000]" :"" } `} 
                        onClick={handleChange}
                        disabled = {formValues.activity_tue === "Skating" && formValues.activity_tue != null}
                      > Advanced Yoga
                      </button>

                      <button 
                        type='button' 
                        name='activity_tue' 
                        value="Drama & Theatre" 
                        className={`bg-white rounded-md border-[1.8px] border-[#808080] text-black font-semibold font-[Causten] text-[14px] h-[60px] w-[115px] ${formValues.activity_tue === "Drama & Theatre" ? "!bg-[#515d45] text-white font-bold" :formErrors.activity_tue === 1 ? "border-2 !border-[#FF0000]" :"" } `} 
                        onClick={handleChange}
                        disabled = {formValues.activity_tue === "Skating" && formValues.activity_tue != null}
                      > Drama & Theatre
                      </button>

                      <button 
                        type='button' 
                        name='activity_tue' 
                        value="Ukelele" 
                        className={`bg-white rounded-md border-[1.8px] border-[#808080] text-black font-semibold font-[Causten] text-[14px] h-[60px] w-[115px] ${formValues.activity_tue === "Ukelele" ? "!bg-[#515d45] text-white font-bold" :formErrors.activity_tue === 1 ? "border-2 !border-[#FF0000]" :"" } `} 
                        onClick={handleChange}
                        disabled = {formValues.activity_tue === "Skating" && formValues.activity_tue != null}
                      > Ukelele
                      </button>

                      <button 
                        type='button' 
                        name='activity_tue' 
                        value="Guitar" 
                        className={`bg-white rounded-md border-[1.8px] border-[#808080] text-black font-semibold font-[Causten] text-[14px] h-[60px] w-[115px] ${formValues.activity_tue === "Guitar" ? "!bg-[#515d45] text-white font-bold" :formErrors.activity_tue === 1 ? "border-2 !border-[#FF0000]" :"" } `} 
                        onClick={handleChange}
                        disabled = {formValues.activity_tue === "Skating" && formValues.activity_tue != null}
                      > Guitar
                      </button>

                      <button 
                        type='button' 
                        name='activity_tue' 
                        value="Classical Music" 
                        className={`bg-white rounded-md border-[1.8px] border-[#808080] text-black font-semibold font-[Causten] text-[14px] h-[60px] w-[115px] ${formValues.activity_tue === "Classical Music" ? "!bg-[#515d45] text-white font-bold" :formErrors.activity_tue === 1 ? "border-2 !border-[#FF0000]" :"" } `} 
                        onClick={handleChange}
                        disabled = {formValues.activity_tue === "Skating" && formValues.activity_tue != null}  
                      >Classical Vocal Music (Carnatic)
                      </button>
                  </div>
              </div>

              {/* Tue & Friday Buttons */}
              <div>
                <h3 className='font-[Causten] text-[18px] text-black font-semibold mb-3'>Tuesday & Friday 3.30PM</h3>

                <button 
                    type='button' 
                    name='activity_tue' 
                    value="Skating" 
                    className={`bg-white rounded-md border-[1.8px] border-[#808080] text-black font-semibold font-[Causten] text-[14px] py-4 w-[110px] ${formValues.activity_tue === "Skating" ? "!bg-[#515d45] text-white font-bold" :formErrors.activity_tue === 1 ? "border-2 !border-[#FF0000]" :"" } `} 
                    onClick={handleChange}
                    disabled= {formValues.activity_tue != "Skating" && formValues.activity_tue != null}
                > Skating </button>
              </div>

              {/* Saturday Button */}
              <div>
                <h3 className='font-[Causten] text-[18px] text-black font-semibold mb-3'>Saturday</h3>

                <button 
                    type='button' 
                    name='activity_sat' 
                    value="Swimming" 
                    className={`bg-white rounded-md border-[1.8px] border-[#808080] text-black font-semibold font-[Causten] text-[14px] py-4 w-[110px] ${formValues.activity_sat === "Swimming" ? "!bg-[#515d45] text-white font-bold" :formErrors.activity_sat === 1 ? "border-2 !border-[#FF0000]" :"" } `} 
                    onClick={handleChange}>Swimming
                  </button>
              </div>


              <div className='flex flex-col gap-y-[6px] mt-7'>
                <label className='form-label'>Parent Name</label>
                <input 
                  className={formErrors.parent_name > 0 ? "form-error-input" : "form-input"} 
                  name= 'parent_name'
                  type='text' 
                  placeholder= 'John Doe'
                  value = {formValues.parent_name}
                  onChange={handleChange}
                />
              
              </div>

              <div className='flex flex-col gap-y-[6px]'>
                <label className='form-label'>Learner Name</label>
                <input 
                  className={formErrors.learner_name > 0 ? "form-error-input" : "form-input"} 
                  name = "learner_name"
                  type='text' 
                  placeholder = "Mukund"
                  value = {formValues.learner_name}
                  onChange={handleChange}
                />
              </div>

              <div className='flex flex-col gap-y-[6px]'>
                <label className='form-label'>Learner Email</label>
                <input 
                  className={formErrors.learner_email > 0 ? "form-error-input" : "form-input"} 
                  name = "learner_email"
                  type='text' 
                  placeholder= "you@gmail.com"
                  value = {formValues.learner_email}
                  onChange={handleChange}
                />
              </div>

              <div className='flex flex-col gap-y-[6px]'>
                <label className='form-label'>Grade</label>
                <select className={formErrors.grade > 0 ? "form-error-select": "form-select"} defaultValue="Select an Option" name="grade" onChange={handleChange}>
                  <option disabled>Select an Option</option>
                  <option value="G1">G1</option>
                  <option value="G2">G2</option>
                  <option value="G3">G3</option>
                  <option value="G4">G4</option>
                  <option value="G5">G5</option>
                </select>
              </div>

              {/* <div className='flex flex-col gap-y-[5px]'>
                <label className='form-label'>Physical Development</label>
                <select className={formErrors.grade > 0 ? "form-error-select": "form-select"} defaultValue="Select an Option" name='physical_mon' onChange={handleChange}>
                  <option disabled>Select an Option</option>
                  <option value="Basketball">Basketball</option>
                  <option value="Silambam/Kambam">Silambam/Kambam</option>
                  <option value="Speech & Drama">Speech & Drama</option>
                  <option value="Piano">Piano</option>
                  <option value="Handwriting">Handwriting</option>
                </select>
                <p className='form-slots'>Monday & Wednesday 3:30 PM</p>
              </div>

              <div className='flex flex-col gap-y-[6px]'>
                <label className='form-label'>Physical Development</label>
                <select className={formErrors.grade > 0 ? "form-error-select": "form-select"} defaultValue="Select an Option" name='physical_tue' onChange={handleChange}>
                  <option disabled>Select an Option</option>
                  <option value ="Soccer">Soccer (Foundation)</option>
                  <option value ="Pickleball">Pickleball</option>
                  <option value ="Yoga">Advanced Yoga</option>
                  <option value ="Drama & Theatre">Drama & Theatre</option>
                  <option value ="Ukelele">Ukelele</option>
                  <option value ="Guitar">Guitar</option>
                  <option value ="Classic Vocal Music (Carnatic)">Classic Vocal Music (Carnatic)</option>
                </select>
                <p className='form-slots'>Tueday & Thursday 3:30 PM</p>
          
              </div>

              <div className='flex flex-col gap-y-[6px]'>
                <label className={test === false ? "form-label" : "form-label line-through"}>Enrichment Activities </label>
                <select className={formErrors.grade > 0 ? "form-error-select": "form-select"} defaultValue="Select an Option" name='enrichment_mon' onChange={handleChange} >
                  <option disabled>Select an Option</option>
                  <option>Test</option>
                  <option>Test</option>
                </select>
                <p className='form-slots'>Monday & Wednesday 3:30 PM</p>
              </div>

              <div className='flex flex-col gap-y-[6px]'>
                <label className='form-label'>Enrichment Activities</label>
                <select className={formErrors.grade > 0 ? "form-error-select": "form-select"} defaultValue="Select an Option" name='enrichment_tue' onChange={handleChange}>
                  <option disabled>Select an Option</option>
                  <option>Test</option>
                  <option>Test</option>
                </select>
                <p className='form-slots'>Tuesday & Thursday 3:30 PM</p>
              </div>

              <div className='flex flex-col gap-y-[6px]'>
                <label className='form-label'>Swimming </label>
                <select className={formErrors.grade > 0 ? "form-error-select": "form-select"} defaultValue="Select an Option" name='swimming' onChange={handleChange}>
                  <option disabled>Select an Option</option>
                  <option>Yes</option>
                  <option>No</option>
                </select>
              </div> */}

              
              {/* <div className='flex flex-col gap-y-[6px]'>
                <label className='form-label'>Payment Plan </label>
                <select className={formErrors.grade > 0 ? "form-error-select": "form-select"} defaultValue="Select an Option" name='payment_plan' onChange={handleChange}>
                  <option disabled>Select an Option</option>
                  <option>Test</option>
                  <option>Test</option>
                </select>
              </div> */}

                <div className={formValues.activity_mon || formValues.activity_sat || formValues.activity_tue != null ? 'flex flex-row gap-x-7 items-center mt-5': "hidden"}>
                  <h5 className='form-label'>Amount to be Paid :</h5>
                  <h5 className='font-[Causten] text-[18px] font-bold'>₹ {formValues.amount}</h5>
                
                </div>

              <button type='submit' className='bg-[#515d45] px-5 py-3 mt-5 text-[#FFF] w-[50%] mx-auto'>Pay Now</button>
        
            </div>

            

            
          </form>
          

        </div>
      </div>
    
    </>
  );
}

export default Primary;
