import React from "react";
import {Route, Routes, Navigate} from 'react-router';

import Primary from "./Primary"
import EarlyYears from "./EarlyYears"


function App(){
  return (
    <Routes>
      <Route path="/*" element={<Navigate to ="/primary" />}/>
      <Route path="/primary" element={<Primary />}/>
      <Route path= "/early_years" element={<EarlyYears />} />
    </Routes>
  )
}


export default App;